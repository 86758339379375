<template>
  <header class="custom-header bg-custom-gradient p-2 flex items-center justify-between md:max-w-[670px] mx-auto fixed top-0 left-0 right-0 z-50 rounded-b-lg">
    <!-- ปุ่มย้อนกลับ -->
    <button @click="goBack" class="back-button flex items-center">
      <img src="@/assets/icons/back-arrow.png" alt="Back" class="icon h-6 w-6 mr-1" />
      <span class="back-text">ย้อนกลับ</span>
    </button>

    <!-- โลโก้ที่อยู่ตรงกลางและมีเอฟเฟกต์ shake แบบขึ้นลง -->
    <img src="@/assets/Deluxe168/logo/Deluxe168-logo.png" alt="Deluxe168 Logo" class="logo h-14 max-w-full object-contain shake" />
  </header>
</template>

<script>
export default {
  name: "Header",
  methods: {
    goBack() {
      this.$router.go(-1); // ย้อนกลับไปยังหน้าก่อนหน้าในประวัติการเข้าชม
    },
  }
};
</script>

<style scoped>
.custom-header {
  background: linear-gradient(to right, #57C4DD, #368AB7); /* สีพื้นหลัง */
  border-bottom: 2px solid #EECE61;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
}

.back-button {
  background: linear-gradient(to right, #ffe100, #beaa10); /* สีพื้นหลังของปุ่มย้อนกลับ */
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.back-text {
  font-size: 1rem;
  font-weight: 500;
}

.icon {
  width: 24px;
  height: 24px;
}

/* สไตล์ไอคอนปุ่มขวาสุด */
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

</style>
