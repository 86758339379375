<!-- SEO.vue -->
<template>
  <div class="seo-container md:max-w-[670px] mx-auto">
    <div v-if="isActive === 1" class="content-section">
      <h1 class="title">
        เกมสล็อตภาพสวยจากค่าย PG เดิมพันได้เงินจริงกับ Deluxe168?
      </h1>
      <p class="description">
        หากคุณกำลังมองหาเว็บไซต์ที่เชื่อถือได้สำหรับการเล่นสล็อตออนไลน์เพื่อทำกำไรจริง
        Deluxe168 คือคำตอบที่สมบูรณ์แบบ ด้วยความปลอดภัยและการอัปเดตเกมใหม่ๆ
        อย่างสม่ำเสมอ Deluxe168
        ได้รับความนิยมเป็นอย่างมากในหมู่นักพนันที่ต้องการเล่นเกมสล็อตออนไลน์ภาพสวยและได้เงินจริง
      </p>
      <img src="@/assets/Deluxe168/slider/testpgslot2.png" />
      <h1 class="title">เล่นเกมสล็อตภาพสวยจากค่าย PG ที่ Deluxe168</h1>
      <ol class="points">
        <li>
          ระบบมาตรฐานและปลอดภัย: ทุกเกมใน Deluxe168
          ได้รับการตรวจสอบและรับรองจากหน่วยงานที่เชื่อถือได้
          ทำให้สมาชิกมั่นใจได้ว่าการเล่นทุกครั้งนั้นปลอดภัยและโปร่งใสอย่างแท้จริง
        </li>
        <li>
          แตกง่าย แจกเยอะ: เกมสล็อตใน Deluxe168 มีอัตราการชนะที่สูงมาก
          ทำให้สมาชิกสามารถเพิ่มโอกาสการทำกำไรได้อย่างต่อเนื่อง
          โดยไม่ต้องเสี่ยงที่จะถูกกั๊ก หรือหลอกลวง
          อีกทั้งยังมีรางวัลโบนัสแตกง่ายที่สุด ทั้งในประเทศไทยและต่างประเทศ
        </li>
        <li>
          ทดลองเล่นฟรีไม่จำกัด: เปิดให้สมาชิกและผู้ที่สนใจสามารถทดลองเล่นได้ตลอด
          24 ชั่วโมง โดยไม่จำเป็นต้องสมัครหรือมียูสเซอร์
          ทำให้สามารถสัมผัสประสบการณ์การเล่นที่น่าตื่นเต้นได้ฟรี ทดลองเล่นสล็อต
          pg ฟรีได้เงินจริง พร้อมโอกาสในการทำเงินโดยไม่ต้องเสียค่าใช้จ่ายเลย
        </li>
        <li>
          อัปเดตเกมสล็อตใหม่อย่างต่อเนื่อง: Deluxe168 มีการอัปเดตเกมใหม่ๆ
          ทุกสัปดาห์ เพื่อให้สมาชิกได้สัมผัสประสบการณ์ที่หลากหลายและทันสมัย
        </li>
      </ol>
    </div>

    <!-- Content for isActive === 2 -->
    <div v-else-if="isActive === 2" class="content-section">
      <h1 class="title">
        ทำไมต้องทดลองเล่นสล็อตกับ Deluxe168 ดีกว่าเว็บทั่วไปอย่างไร?
      </h1>
      <p class="description">
        เมื่อเปรียบเทียบกับเว็บสล็อตทั่วไป การเลือกเล่นกับ Deluxe168
        ถือว่ามีข้อได้เปรียบในหลายๆ ด้าน โดยเฉพาะในเรื่องของความปลอดภัย
        และการให้บริการเกมสล็อตลิขสิทธิ์แท้จากค่ายเกมชื่อดังต่างๆ นอกจากนี้
        Deluxe168 ยังไม่คิดค่าธรรมเนียมการใช้งานใดๆ เพิ่มเติม
        อีกทั้งยังมีการจัดโปรโมชั่นและข้อเสนอพิเศษให้กับสมาชิกทุกท่านอย่างสม่ำเสมอ
        รวมถึงการสนับสนุนลูกค้าอย่างเต็มที่
        ทำให้สมาชิกมีความมั่นใจมากขึ้นในการทำกำไรในระดับสูง
      </p>
      <img src="@/assets/Deluxe168/slider/testpgslot.png" />
      <h1 class="title">จุดเด่นของ Deluxe168 ที่คุณไม่ควรพลาด?</h1>
      <ol class="points">
        <li>
          ระบบมาตรฐานและปลอดภัย: ทุกเกมใน Deluxe168
          ได้รับการตรวจสอบและรับรองจากหน่วยงานที่เชื่อถือได้
          ทำให้สมาชิกมั่นใจได้ว่าการเล่นทุกครั้งนั้นปลอดภัยและโปร่งใสอย่างแท้จริง
        </li>
        <li>
          แตกง่าย แจกเยอะ: เกมสล็อตใน Deluxe168 มีอัตราการชนะที่สูงมาก
          ทำให้สมาชิกสามารถเพิ่มโอกาสการทำกำไรได้อย่างต่อเนื่อง
          โดยไม่ต้องเสี่ยงที่จะถูกกั๊ก หรือหลอกลวง
          อีกทั้งยังมีรางวัลโบนัสแตกง่ายที่สุด ทั้งในประเทศไทยและต่างประเทศ
        </li>
        <li>
          ทดลองเล่นฟรีไม่จำกัด: เปิดให้สมาชิกและผู้ที่สนใจสามารถทดลองเล่นได้ตลอด
          24 ชั่วโมง โดยไม่จำเป็นต้องสมัครหรือมียูสเซอร์
          ทำให้สามารถสัมผัสประสบการณ์การเล่นที่น่าตื่นเต้นได้ฟรี ทดลองเล่นสล็อต
          pg ฟรีได้เงินจริง พร้อมโอกาสในการทำเงินโดยไม่ต้องเสียค่าใช้จ่ายเลย
        </li>
        <li>
          อัปเดตเกมสล็อตใหม่อย่างต่อเนื่อง: Deluxe168 มีการอัปเดตเกมใหม่ๆ
          ทุกสัปดาห์ เพื่อให้สมาชิกได้สัมผัสประสบการณ์ที่หลากหลายและทันสมัย
        </li>
      </ol>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "SEO",
  props: {
    isActive: {
      type: Number,
      required: true,
    },
  },
};
</script>
  
  <style scoped>
.seo-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.content-section {
  text-align: left;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #024f97;
  margin-bottom: 10px;
}

.game-image {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.description {
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
}

.points {
  font-size: 1rem;
  color: #111;
  padding-left: 20px;
  list-style:decimal;
}

.points li {
  margin-bottom: 10px;
}
</style>
  