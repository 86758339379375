import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "material-icons/iconfont/material-icons.css";

// สร้าง WebSocket connection
const socket = new WebSocket(process.env.VUE_APP_WS_URL);

// กำหนดการเชื่อมต่อ WebSocket
socket.onopen = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  };
  
  socket.onmessage = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  };
  
  socket.onclose = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  };
  

// ใช้ provide เพื่อนำ WebSocket เข้าไปในทุก component
const app = createApp(App);

app.use(store)
   .use(router)
   .provide("socket", socket) // ส่ง socket เข้าไปในแอปทั้งหมด
   .mount("#app");
