<template>
  <header class="custom-header bg-custom-gradient p-2 flex items-center justify-center md:max-w-[670px] mx-auto fixed top-0 left-0 right-0 z-50 rounded-b-lg">
    <img src="@/assets/Deluxe168/logo/Deluxe168-logo.png" alt="Deluxe168 Logo" class="logo h-14 max-w-full object-contain" />
  </header>
</template>

<script>
export default {
  name: "Header"
};
</script>

<style scoped>
.custom-header {
  background: linear-gradient(to right, #57C4DD, #368AB7); /* ปรับสีให้ตรงกับดีไซน์ */
  border-bottom: 2px solid #EECE61; /* เพิ่มขอบเฉพาะด้านล่าง */
  /* border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; */
  overflow: hidden; /* ป้องกันเนื้อหาล้น */
}
.logo {
  transform: translate(-0%, -0%) scale(1.3); /* ขยายขนาดโลโก้และวางให้อยู่ตรงกลาง */
  z-index: 10; /* เพื่อให้โลโก้ทับส่วนอื่นๆ */
  filter: drop-shadow(0 0 10px #00e6e6) drop-shadow(0 0 20px #00e6e6) drop-shadow(0 0 30px #00e6e6); /* เพิ่มแสงเงา */
}

</style>
