<template>
  <div class="relative w-full overflow-hidden  md:max-w-[670px] mx-auto">
    <div
      class="flex transition-transform duration-500"
      :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
    >
      <div v-for="(image, index) in images" :key="index" class="min-w-full">
        <img
          :src="image"
          alt="Slider Image"
          class="w-full h-auto object-cover"
        />
      </div>
    </div>
     
  </div>
</template>

<script>
export default {
  name: "Slider",
  data() {
    return {
      currentIndex: 0,
      images: [
        require("@/assets/Deluxe168/slider/banner1.png"),
        require("@/assets/Deluxe168/slider/banner2.png"),
        require("@/assets/Deluxe168/slider/banner3.png"),
        require("@/assets/Deluxe168/slider/banner4.png"),
      ],
    };
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
  },
  mounted() {
    // Auto-slide functionality
    this.autoSlide = setInterval(this.nextSlide, 3000);
  },
  beforeUnmount() {
    // Clear interval when component is destroyed
    clearInterval(this.autoSlide);
  },
};
</script>

<style scoped>
/* คุณสามารถปรับแต่งสไตล์เพิ่มเติมได้ที่นี่ */
.min-w-full {
  min-width: 100%;
}
</style>
