<template>
  <div class="bg-gradient p-1 overflow-hidden whitespace-nowrap  md:max-w-[670px] mx-auto">
    <p class="animate-marquee text-white font-bold text-lg">
      DELUXE168 สุดยอดเว็บสล็อตอันดับ 1 ในไทย ฝาก-ถอนด้วยระบบอัตโนมัติ เล่นสล็อตได้ทุกที่ 
      ทุกเวลา รองรับการเล่นผ่านมือถือทุกระบบ พร้อมโบนัสและรางวัลมากมาย
    </p>
  </div>
</template>

<script>
export default {
  name: "Textarea",
};
</script>

<style scoped>
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: inline-block;
  animation: marquee 20s linear infinite;
}
.bg-gradient {
  background-color: #080808;
  margin-top: 0px;
}

</style>
