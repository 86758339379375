<template>
    <div class="game-teak-container md:max-w-[670px] mx-auto">
      <div class="game-teak-slider-wrapper">
        <div
          class="game-teak-slider"
          :style="{ transform: `translateX(-${scrollPosition}px)` }"
        >
          <div v-for="(image, index) in infiniteImages" :key="index" class="game-teak-slide">
            <img :src="image.src" :alt="image.alt" class="game-teak-slide-image" />
          </div>
        </div>
      </div>
      <p class="game-teak-caption">รายการลูกค้าได้ Jackpot ที่ DELUXE168</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "GameTeak",
    data() {
      return {
        images: [
          { src: require("@/assets/Deluxe168/slider/Jackpot1.jpg"), alt: "Slide 1" },
          { src: require("@/assets/Deluxe168/slider/Jackpot2.jpg"), alt: "Slide 2" },
          { src: require("@/assets/Deluxe168/slider/Jackpot3.jpg"), alt: "Slide 3" },
          { src: require("@/assets/Deluxe168/slider/Jackpot4.jpg"), alt: "Slide 4" },
          { src: require("@/assets/Deluxe168/slider/Jackpot5.jpg"), alt: "Slide 5" },
          { src: require("@/assets/Deluxe168/slider/Jackpot6.jpg"), alt: "Slide 6" },
          { src: require("@/assets/Deluxe168/slider/Jackpot7.jpg"), alt: "Slide 7" },
          { src: require("@/assets/Deluxe168/slider/Jackpot8.jpg"), alt: "Slide 8" },
        ],
        scrollPosition: 0,
        slideWidth: 0,
      };
    },
    computed: {
      infiniteImages() {
        // สร้างอาร์เรย์ของภาพที่ถูกซ้ำเพื่อให้เลื่อนไม่มีที่สิ้นสุด
        return [...this.images, ...this.images, ...this.images];
      },
    },
    mounted() {
      this.startAutoSlide();
      this.calculateSlideWidth();
      window.addEventListener("resize", this.calculateSlideWidth);
    },
    beforeUnmount() {
      clearInterval(this.interval);
      window.removeEventListener("resize", this.calculateSlideWidth);
    },
    methods: {
      startAutoSlide() {
        this.interval = setInterval(() => {
          this.scrollPosition += 1; // เพิ่ม scrollPosition ทีละ 1 เพื่อให้ภาพเลื่อน
          if (this.scrollPosition >= this.slideWidth * this.images.length) {
            this.scrollPosition = 0; // รีเซ็ต scrollPosition เมื่อเลื่อนภาพครบแล้ว
          }
        }, 20); // เลื่อนทุก 20 ms เพื่อความนุ่มนวล
      },
      calculateSlideWidth() {
        // คำนวณความกว้างของสไลด์
        const slideElement = document.querySelector(".game-teak-slide");
        this.slideWidth = slideElement ? slideElement.clientWidth : 0;
      },
    },
  };
  </script>
  
  <style scoped>
  .game-teak-container {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 150px;
    background: linear-gradient(to right, #21b3e9, #407aeb, #4472eb);
  }
  
  .game-teak-slider-wrapper {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
  
  .game-teak-slider {
    display: flex;
    transition: none; /* ไม่มี transition เพื่อให้การเลื่อนต่อเนื่อง */
    white-space: nowrap;
  }
  
  .game-teak-slide {
    flex: 0 0 50%; /* แสดง 3 รูปภาพพร้อมกันในหน้าจอ */
    box-sizing: border-box;
    padding: 0 5px;
  }
  
  .game-teak-slide-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .game-teak-caption {
    text-align: center;
    font-size: 1rem;
    color: #ffffff;
    margin-top: 10px;
    font-weight: bold;
  }
  </style>
  