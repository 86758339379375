<template>
  <div class="md:max-w-[670px] mx-auto">
    <!-- Button Group -->
    <div class="btn-game-container flex gap-4 p-2 justify-center">
      <!-- Button 1 (Active on load) -->
      <div
        :class="['btn', isActive === 1 ? 'btn-primary-active' : 'btn-primary']"
        @click="setActive(1, 'สล็อตเว็บตรง')"
        class="relative flex items-center p-6 rounded-lg overflow-hidden cursor-pointer"
      >
        <div class="text-content">
          <h3
            :class="isActive === 1 ? 'text-black' : 'text-white'"
            class="text-xl font-bold italic leading-tight"
          >
            สล็อตเว็บตรง
          </h3>
          <p
            :class="isActive === 1 ? 'text-black' : 'text-white'"
            class="text-sm leading-tight"
          >
            ลิขสิทธิ์แท้จาก PG
          </p>
        </div>
        <img
          src="@/assets/Deluxe168/MenuPro/BtnGame1.png"
          alt="Game Image 1"
          class="game-image absolute"
        />
      </div>

      <!-- Button 2 -->
      <div
        :class="[
          'btn',
          isActive === 2 ? 'btn-secondary-active' : 'btn-secondary',
        ]"
        @click="setActive(2, 'ทดลองเล่นฟรี')"
        class="relative flex items-center p-6 rounded-lg overflow-hidden cursor-pointer"
      >
        <div class="text-content">
          <h3
            :class="isActive === 2 ? 'text-black' : 'text-white'"
            class="text-xl font-bold italic leading-tight"
          >
            ทดลองเล่นฟรี
          </h3>
          <p
            :class="isActive === 2 ? 'text-black' : 'text-white'"
            class="text-sm leading-tight"
          >
            จาก PG โดยตรง
          </p>
        </div>
        <img
          src="@/assets/Deluxe168/MenuPro/BtnGame2.png"
          alt="Game Image 2"
          class="game-image absolute"
        />
      </div>
    </div>

    <!-- Header Container -->
    <div class="header-container">
      <div class="fire-icon">
        <img
          src="@/assets/icons/fire.gif"
          alt="Fire Icon"
          class="w-full h-full"
        />
      </div>
      <h2 class="text-lg font-semibold text-white">
        {{
          isActive === 2
            ? "ทดลองเล่นฟรี เกมแท้จาก PG โดยตรง"
            : "20 เกมส์มาแรงในตอนนี้ " + currentDate
        }}
      </h2>
    </div>

    <!-- Game List in Grid -->
    <div class="grid grid-cols-4 gap-2 p-2">
      <div v-for="(game, index) in filteredGameList" :key="index">
        <div class="cursor-pointer mb-2">
          <!-- แยกการเปิดลิงก์สำหรับ gameList และ PGdemogame -->
          <a v-if="isActive === 1" @click.prevent="openNewTab(game.url)">
            <img :src="game.image" alt="Game Image" class="w-full h-auto" />
          </a>
          <a v-else @click.prevent="navigateToGame(game.url)">
            <img :src="game.image" alt="Game Image" class="w-full h-auto" />
          </a>
        </div>

        <!-- Progress Bar for gameList only -->
        <div
          v-if="isActive === 1"
          class="relative w-full h-4 bg-gray-200 rounded-full overflow-hidden"
        >
          <div
            class="h-full text-center text-[0.625rem] text-black font-bold flex items-center justify-center moving-background"
            :style="{ width: `${game.breakRate}%` }"
          >
            อัตราแตก {{ game.breakRate }}%
          </div>
        </div>
      </div>
    </div>

    <!-- SEO Component -->
    <SEO :isActive="isActive" />
    <!-- ย้ายมาที่ตำแหน่งนี้ -->
  </div>
</template>
  
<script>
import { defineComponent } from "vue";
import SEO from "@/components/SEO.vue";

export default defineComponent({
  name: "BtnGame",
  components: {
    SEO,
  },
  data() {
    return {
      currentDate: this.getCurrentDate(),
      isActive: 1,
      activeButtonLabel: "", // Track the button name
      gameList: Array.from({ length: 130 }, (_, i) => ({
        image: require(`@/assets/games/${i + 1}.JPEG.png`),
        breakRate: this.randomBreakRate(),
        url: "https://pggame.playauto.cloud/?prefix=UEdTTw",
      })),
      PGdemogame: [
        28, 22, 45, 61, 62, 56, 79, 78, 117, 115, 114, 113, 112, 111, 110, 109,
        108, 107, 106, 105, 104, 103, 102, 101, 99, 98, 97, 96, 95, 94, 93, 92,
      ].map((num) => ({
        image: require(`@/assets/games/${num}.JPEG.png`),
        breakRate: null,
        url: process.env[`VUE_APP_GAME_URL_${num}`],
      })),
      filteredGameList: [],
    };
  },
  methods: {
    setActive(buttonNumber, buttonLabel) {
      this.isActive = buttonNumber;
      this.activeButtonLabel = buttonLabel;
      localStorage.setItem("activeButton", buttonNumber);
      localStorage.setItem("activeButtonLabel", buttonLabel);
      this.updateFilteredGameList();
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    navigateToGame(url) {
      this.$router.push({ name: "Deluxe168PGSLot", query: { url } });
    },
    updateFilteredGameList() {
      this.filteredGameList = this.getRandom20Games(
        this.isActive === 1 ? this.gameList : this.PGdemogame
      );
    },
    getCurrentDate() {
      const now = new Date();
      const options = {
        timeZone: "Asia/Bangkok",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
      return now.toLocaleDateString("en-GB", options);
    },
    getRandom20Games(gameList) {
      if (this.isActive === 1) {
        const shuffled = [...gameList].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 20).map((game) => ({
          ...game,
          breakRate: this.randomBreakRate(),
        }));
      }
      return gameList;
    },
    randomBreakRate() {
      return Math.floor(Math.random() * 10) + 90;
    },
  },
  mounted() {
    const savedButton = localStorage.getItem("activeButton");
    const savedButtonLabel = localStorage.getItem("activeButtonLabel");
    if (savedButton) {
      this.isActive = parseInt(savedButton, 10);
      this.activeButtonLabel = savedButtonLabel || "";
    }
    this.updateFilteredGameList();
    this.shuffleInterval = setInterval(() => {
      this.updateFilteredGameList();
    }, 30000);
  },
  beforeUnmount() {
    clearInterval(this.shuffleInterval);
  },
});
</script>
  
  
  <style scoped>
.btn-game-container {
  display: flex;
  gap: 8px;
}

.btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 70px;
  transition: background 0.3s ease;
  padding: 10px;
  border-radius: 12px;
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* เพิ่มเงาให้ปุ่ม */
}
/* Media query สำหรับหน้าจอที่มีความกว้างอย่างน้อย 700px */
@media (min-width: 700px) {
  .btn {
    max-width: 500px; /* เปลี่ยนขนาดสูงสุดของปุ่ม */
    height: 90px; /* ปรับความสูง */
    padding: 15px; /* เพิ่ม padding */
    border-radius: 15px; /* ปรับความโค้ง */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); /* เพิ่มเงาให้เข้มขึ้น */
  }
  .game-image {
    width: 120px !important;
    height: auto;
    object-fit: contain;
    position: absolute;
    bottom: 10px;
    right: -10px;
    transform: translateY(25%);
    z-index: 5;
  }
}

.btn-primary-active {
  background: linear-gradient(to right, #ffe100, #beaa10);
}

.btn-primary {
  background: linear-gradient(to right, #21b3e9, #407aeb, #4472eb);
}

.btn-primary:hover,
.btn-primary-active:hover {
  background: linear-gradient(to right, #ffe100, #beaa10);
}

.btn-secondary {
  background: linear-gradient(to right, #21b3e9, #407aeb, #4472eb);
}

.btn-secondary-active {
  background: linear-gradient(
    to right,
    #ffe100,
    #beaa10
  ); /* สีพื้นหลังเมื่อ active */
  color: white; /* เปลี่ยนสีตัวอักษรให้เป็นสีขาว */
}

.text-content {
  z-index: 10;
  text-align: left;
}

.game-image {
  width: 80px;
  height: auto;
  object-fit: contain;
  position: absolute;
  bottom: 10px;
  right: -10px;
  transform: translateY(25%);
  z-index: 5;
}

.moving-background {
  background-image: linear-gradient(
    306deg,
    #ffc107 25%,
    #ffc107 25%,
    #ffd700 50%,
    #edb304 50%,
    #ffd700 75%,
    #ffc107 75%,
    #ffc107
  );
  background-size: 80px 100%;
  animation: moveStripes 1s linear infinite;
}

@keyframes moveStripes {
  0% {
    background-position: 40px 0;
  }
  100% {
    background-position: 0 0;
  }
}

.header-container {
  background-color: #080808;
  height: 33px; /* ความสูงของแถบ */
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.fire-icon {
  width: 40px;
  height: 35px;
  margin-right: 0px;
  margin-bottom: 18px; /* ระยะห่างเล็กน้อยระหว่างไอคอนและข้อความ */
}

h2 {
  font-size: 1rem;
  line-height: 1;
  white-space: nowrap;
}
</style>
  