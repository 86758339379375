<template>
    <div class="md:max-w-[670px] mx-auto menu-bottom fixed bottom-0 left-0 right-0 flex justify-around items-center p-2 shadow-lg">
      <!-- Menu Item 1 -->
      <a href="https://pggame.playauto.cloud/register?prefix=UEdTTw" target="_blank" class="menu-item">
        <img src="@/assets/Deluxe168/icons/register.png" alt="สมัครบัญชี" class="icon" />
        <p>สมัครบัญชี</p>
      </a>
  
      <!-- Menu Item 2 -->
      <a href="https://pggame.playauto.cloud/?prefix=UEdTTw" target="_blank" class="menu-item">
        <img src="@/assets/Deluxe168/icons/login.png" alt="เข้าสู่ระบบ" class="icon" />
        <p>เข้าสู่ระบบ</p>
      </a>
  
      <!-- Menu Item 3 -->
      <a href="https://pggame.playauto.cloud/?prefix=UEdTTw" target="_blank" class="menu-item">
        <img src="@/assets/Deluxe168/icons/promotion.png" alt="โปรโมชั่น" class="icon" />
        <p>โปรโมชั่น</p>
      </a>
  
      <!-- Menu Item 4 -->
      <a href="https://line.me/R/ti/p/@721oiqfq?oat_content=url&ts=10272355" target="_blank" class="menu-item">
        <img src="@/assets/Deluxe168/icons/contact.png" alt="ติดต่อ" class="icon" />
        <p>ติดต่อ</p>
      </a>
    </div>
  </template>
  
  <script>
  export default {
    name: "MenuBottom",
  };
  </script>
  
  <style scoped>
  .menu-bottom {
    background: linear-gradient(to right, #57C4DD, #368AB7); /* Main background gradient */
    border-top: 2px solid #eeb702; /* Updated border color */
    z-index: 50;
    padding: 10px 0;
    gap: 2x; /* Reduced gap between items */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, #f0c600, #f5d12a); /* Button gradient */
    border-radius: 12px;
    margin-left: 5px;
    margin-right: 5px;
    width: 92px;
    height: 80px; /* Adjusted height to maintain consistent look */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    text-decoration: none;
    color: black;
  }
  
  .menu-item:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .icon {
    width: 40px;
    height: 40px;
    margin-bottom: 4px;
  }
  
  .menu-item p {
    font-size: 0.76rem;
    font-weight: bold;
    margin: 0;
  }
  </style>
  