<template>
    <div class="p-2 md:max-w-[670px] mx-auto">
        <!-- Header with Icon -->
        <div class="flex items-end space-x-2 mb-2">
            <div class="w-10 h-10 mb-1">
                <img src="@/assets/icons/fire.gif" alt="Fire Icon" class="w-full h-full" />
            </div>
            <h2 class="text-xl font-semibold text-white">
                อัตราแกมแตก {{ currentDate }}
            </h2>
        </div>

        <!-- Tab Menu -->
        <div class="flex justify-center items-center p-2 rounded-full mb-0 backdrop: space-x-2">
            <button v-for="(tab, index) in tabs" :key="index" @click="activeTab = tab.name" :class="{
                'bg-[#ffe600] text-black border-2 border-[#EECE61]':
                    activeTab === tab.name,
                'bg-gradient-to-br from-[#172f82] to-[#180650] text-white border border-[#EECE61] hover:bg-[#ffe600] hover:text-black':
                    activeTab !== tab.name,
            }" class="flex items-center w-full justify-center px-3 py-1 transition rounded-full mb-2 flex-nowrap">
                <img :src="tab.icon" alt="tab icon" class="w-4 h-4 mr-2" />
                <span class="text-sm flex-grow text-center whitespace-nowrap">{{
                    tab.label
                    }}</span>
            </button>
        </div>

        <!-- Game List in Grid -->
        <div class="grid grid-cols-4 gap-2">
            <div v-for="(game, index) in filteredGameList" :key="index" class="shadow-lg">
                <!-- Click to navigate to game detail -->
                <div @click="viewGameDetail(game)" class="cursor-pointer mb-2">
                    <img :src="game.image" alt="Game Image" class="w-full h-auto" />
                </div>

                <!-- Progress Bar -->
                <div class="relative w-full h-4 bg-gray-200 rounded-full overflow-hidden">
                    <div class="h-full text-center text-[0.625rem] text-black font-bold flex items-center justify-center moving-background"
                        :style="{ width: `${game.breakRate}%` }">
                        อัตราแตก {{ game.breakRate }}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "GameList",
    data() {
        return {
            currentDate: this.getCurrentDate(),
            activeTab: "pg", // ค่าแท็บที่เลือกตอนเริ่มต้น
            tabs: [
                {
                    name: "pg",
                    label: "ค่ายเกม PG",
                    icon: require("@/assets/icons-tab/pg-icon.png"),
                },
                {
                    name: "all",
                    label: "ค่ายเกมรวม",
                    icon: require("@/assets/icons-tab/all-icon.png"),
                },
                {
                    name: "baccarat",
                    label: "สูดเล่น บาคาร่า",
                    icon: require("@/assets/icons-tab/baccarat-icon.png"),
                },
            ],
            gameList: [
                {
                    name: "Yaksa of Honor",
                    image: require("@/assets/games/1.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Shark Hunter",
                    image: require("@/assets/games/2.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Jungle Party",
                    image: require("@/assets/games/3.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Chicken Run",
                    image: require("@/assets/games/4.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Zombie Invasion",
                    image: require("@/assets/games/5.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Anubis Wrath",
                    image: require("@/assets/games/6.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Mystic Potion",
                    image: require("@/assets/games/7.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Cash Mania",
                    image: require("@/assets/games/8.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Wild Ape",
                    image: require("@/assets/games/9.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Pinata Wins",
                    image: require("@/assets/games/10.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Treasures Gold",
                    image: require("@/assets/games/11.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Fortune Piggy",
                    image: require("@/assets/games/12.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Dragon Hatch",
                    image: require("@/assets/games/13.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Werewolves Hunt",
                    image: require("@/assets/games/14.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Jespar Treasures",
                    image: require("@/assets/games/15.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Mafia Mayhem",
                    image: require("@/assets/games/16.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Forge of Wealth",
                    image: require("@/assets/games/17.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Wild West Shootout",
                    image: require("@/assets/games/18.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Ultimate Striker",
                    image: require("@/assets/games/19.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Ninja Dragon",
                    image: require("@/assets/games/20.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Gladiators Glory",
                    image: require("@/assets/games/21.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Safari Wilds",
                    image: require("@/assets/games/22.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Cruise Royale",
                    image: require("@/assets/games/23.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Fruity Candy",
                    image: require("@/assets/games/24.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Super Golf Drive",
                    image: require("@/assets/games/25.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Mystical Treasures",
                    image: require("@/assets/games/26.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Strip Khan",
                    image: require("@/assets/games/27.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Bakery Bonanza",
                    image: require("@/assets/games/28.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Holiday Riches",
                    image: require("@/assets/games/29.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
                {
                    name: "Mystical Potion",
                    image: require("@/assets/games/30.JPEG.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "pg",
                    provider: "PG",
                },
            ],

            gameListAll: [
                {
                    name: "Rave Riches",
                    image: require("@/assets/games-all/game-all-1.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Paddy's Pub",
                    image: require("@/assets/games-all/game-all-2.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "T-Rex",
                    image: require("@/assets/games-all/game-all-3.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Ruler of the Dead",
                    image: require("@/assets/games-all/game-all-4.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Mermaid Riches",
                    image: require("@/assets/games-all/game-all-5.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "PG",
                },
                {
                    name: "Legendary Luck",
                    image: require("@/assets/games-all/game-all-6.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Chunky Monkey",
                    image: require("@/assets/games-all/game-all-7.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Fired Up",
                    image: require("@/assets/games-all/game-all-8.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Megarun",
                    image: require("@/assets/games-all/game-all-9.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "USA Flip",
                    image: require("@/assets/games-all/game-all-10.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Wild Bandolier",
                    image: require("@/assets/games-all/game-all-11.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Rascal Riches",
                    image: require("@/assets/games-all/game-all-12.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Mystic Pharaoh",
                    image: require("@/assets/games-all/game-all-13.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Volcano of Riches",
                    image: require("@/assets/games-all/game-all-14.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Scroll of Seth",
                    image: require("@/assets/games-all/game-all-15.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Sparkling Jewels",
                    image: require("@/assets/games-all/game-all-16.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Sweet Nectar 100",
                    image: require("@/assets/games-all/game-all-17.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Triple Prosperity",
                    image: require("@/assets/games-all/game-all-18.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Razor Sharks",
                    image: require("@/assets/games-all/game-all-19.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Fat Kings",
                    image: require("@/assets/games-all/game-all-20.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Tiki Tumble",
                    image: require("@/assets/games-all/game-all-21.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Mystery Museum",
                    image: require("@/assets/games-all/game-all-22.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Flaming Joker",
                    image: require("@/assets/games-all/game-all-23.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Fat Dracula",
                    image: require("@/assets/games-all/game-all-24.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Shadow Order",
                    image: require("@/assets/games-all/game-all-25.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Mad Carts",
                    image: require("@/assets/games-all/game-all-26.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Joker's Troupe",
                    image: require("@/assets/games-all/game-all-27.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Wild Swarm",
                    image: require("@/assets/games-all/game-all-28.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Jingle Bells",
                    image: require("@/assets/games-all/game-all-29.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Epic Journey",
                    image: require("@/assets/games-all/game-all-30.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Emerald Diamond",
                    image: require("@/assets/games-all/game-all-31.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Elven Magic",
                    image: require("@/assets/games-all/game-all-32.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Dragon's Fire",
                    image: require("@/assets/games-all/game-all-33.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Cinderella",
                    image: require("@/assets/games-all/game-all-34.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Cursed Castle",
                    image: require("@/assets/games-all/game-all-35.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Blue Diamond",
                    image: require("@/assets/games-all/game-all-36.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Big Wheel",
                    image: require("@/assets/games-all/game-all-37.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Ancient Script",
                    image: require("@/assets/games-all/game-all-38.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Magic Beast",
                    image: require("@/assets/games-all/game-all-39.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
                {
                    name: "Hog Wild",
                    image: require("@/assets/games-all/game-all-40.png"),
                    breakRate: this.randomBreakRate(),
                    tab: "all",
                    provider: "NET",
                },
            ],

            autoShuffle: null, // สำหรับเก็บการเรียก setInterval
        };
    },
    computed: {
        filteredGameList() {
            if (this.activeTab === "all") {
                return this.gameListAll; // แสดงเฉพาะเกมในแท็บ "all"
            }
            return this.gameList.filter((game) => game.tab === this.activeTab); // แสดงเฉพาะเกมตามแท็บที่เลือก (pg หรือ baccarat)
        },
    },
    methods: {
        // ฟังก์ชันสุ่มอัตราแกมแตกระหว่าง 90 ถึง 99
        randomBreakRate() {
            return Math.floor(Math.random() * 10) + 90;
        },

        getCurrentDate() {
            const now = new Date();
            const options = {
                timeZone: "Asia/Bangkok",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            };
            // ใช้ en-GB แทน th-TH เพื่อให้ปีเป็น ค.ศ.
            return now.toLocaleDateString("en-GB", options);
        },

        // ฟังก์ชันนำทางไปยังหน้า Game Detail
        viewGameDetail(game) {
            this.$router.push({
                name: "game-detail",
                params: {
                    gameName: game.name,
                    gameImage: game.image,
                    gameProvider: game.provider, // ส่ง provider ของแต่ละเกม
                },
            });
        },

        // ฟังก์ชันสำหรับสุ่มเรียงลำดับรายการเกมใหม่
        shuffleGameList() {
            // สุ่มเรียงลำดับเกมในรายการ gameList และ gameListAll
            this.gameList = this.gameList.sort(() => Math.random() - 0.5);
            this.gameListAll = this.gameListAll.sort(() => Math.random() - 0.5);
        },
    },

    mounted() {
        this.autoShuffle = setInterval(this.shuffleGameList, 10000);
    },

    beforeUnmount() {
        // ลบ setInterval เมื่อ component ถูกทำลาย
        clearInterval(this.autoShuffle);
    },
});
</script>

<style scoped>
.moving-background {
    background-image: linear-gradient(306deg,
            #ffc107 25%,
            #ffc107 25%,
            #ffd700 50%,
            #edb304 50%,
            #ffd700 75%,
            #ffc107 75%,
            #ffc107);
    background-size: 80px 100%;
    animation: moveStripes 1s linear infinite;
}

@keyframes moveStripes {
    0% {
        background-position: 40px 0;
    }

    100% {
        background-position: 0 0;
    }
}
</style>
