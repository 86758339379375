<template>
    <div class="deluxe-container md:max-w-[670px] mx-auto">
      <!-- Header Component -->
      <HeaderSlotTest />
  
      <!-- Iframe Section -->
      <div class="iframe-container">
        <iframe :src="gameUrl" frameborder="0" class="game-iframe"></iframe>
      </div>
  
      <!-- MenuBottom Component -->
      <MenuBottom />
    </div>
  </template>
  
  <script>
  import HeaderSlotTest from "@/components/HeaderSlotTest.vue";
  import MenuBottom from "@/components/MenuBottom.vue";
  import { defineComponent } from "vue";
  import { useRoute } from "vue-router";
  
  export default defineComponent({
    name: "Deluxe168",
    components: {
      HeaderSlotTest,
      MenuBottom,
    },
    setup() {
      const route = useRoute();
      const gameUrl = route.query.url; // รับ URL จาก query parameter
  
      return { gameUrl };
    },
  });
  </script>
  
  <style scoped>
  .deluxe-container {
    display: flex;
    flex-direction: column;
    height: 90vh;
  }
  
  .iframe-container {
    flex: 1;
    display: flex;
    justify-content: center; /* จัดกึ่งกลางแนวนอน */
    align-items: center; /* จัดกึ่งกลางแนวตั้ง */
    padding: 10px;
  }
  
  .game-iframe {
    width: 80%;
    height: 85%;
    border-radius: 8px;
  }
  </style>
  